body {
  margin: 0;
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  /* font-family: 'Noto Sans', sans-serif; */
  background-color: #fff;
  color: #000;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: 'gok_stencilregular';
  src: url('./fonts/gok_stencil-webfont.woff2') format('woff2'),
       url('./fonts/gok_stencil-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

html {
  scroll-behavior: smooth;
}

.TextSchedule {
  font-family: 'gok_stencilregular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ButtonTeste {
  width: 200px;
  height: 200px
}

img {
  width: 100%;
  height: auto
}

.SliderClientes, .SliderBanner{
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0px;
}

/*
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.cdnfonts.com/css/futura-pt');
*/

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.FormContato {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.InputForm {
  height: 45px;
  margin-top: 5px;
  width: 96%;
  padding-left: 2%;
  font-size: 17px;
}

.InputTextArea {
  height: 135px;
  margin-top: 5px;
  width: 96.2%;
  padding-left: 2%;
  padding-top: 15px;
  font-size: 17px;
}

::-webkit-input-placeholder  { 
  color:#000000; 
  font-weight: 500;
  font-size: 18px;
}
input:-moz-placeholder { 
  color:#000000; 
  font-weight: 500;
  font-size: 18px;
}
textarea:-moz-placeholder { 
  color:#000000; 
  font-weight: 500;
  font-size: 18px;
}

.AlertaJs {
  width: 350px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.TextMensagem {
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.TextMensagem2 {
  color: #ffff;
  font-weight: 500;
  font-size: 17px;
  padding-left: 8%;
}

.TextMensagem3 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.TextMensagem4 {
  font-size: 19px;
  color: #fff;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.BotaoAcessarBD {
   width: 140px;
    height: 32px;
    font-weight: bold;
    background-color: #fff;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
}


.slick-prev, .slick-next {

}

.slick-prev:before, .slick-next:before {
  color: #000 !important;
  font-size: 26px !important;
}

.slick-prev:before, .slick-next:before {
  color: #000 !important;
  font-size: 26px !important;
}

.SliderBanner .slick-next:before, .SliderBanner .slick-prev:before {
  color: #fff !important;
}

.slick-prev {
  left: -65px  !important;
}

.slick-next {
  right: -50px !important;
}

/*
.SliderClientes .slick-slide {
  display: flex !important;
  flex-direction: column !important;
  height: 305px !important;
  justify-content: space-between !important;
}

.SliderClientes .slick-next {
  right: 0px !important;
}


.SliderClientes  {
  display: flex !important;
  align-items: center !important;
}*/

.SliderClientes .slick-prev {
  left: -15px !important;
}

.SliderClientes .slick-next {
  right: -35px !important;
}

.SliderClientes .slick-list {
  padding-top: 15%;
}

.SliderBanner .slick-prev {
  left: 20px !important;
  z-index: 99;
} 

.SliderBanner .slick-next {
  right: 20px !important;
  z-index: 99;
} 

.SliderBanner {
  padding-top: 110px;
}

.SliderCases1 .slick-prev {
  left: 6% !important;
  z-index: 99;
  top: 42% !important;
}

.SliderCases1 .slick-next {
  right: 51% !important;
  z-index: 99;
  top: 42% !important;
}

.SliderImage {
  width: 910px;
  margin: 0 auto;
}

.SliderImage .slick-next:before {
  color: #fff!important;
}

.SliderImage .slick-prev:before {
  color: #fff!important;
}

.SliderImage .slick-next {
  right: -63px!important;
}

.IframeEventos {
  width: 75%;
  margin: 0 auto;
  height: 40vw;
}

.ItemHistoric1 {
  width: 30% ;
}

.ItemHistoric2 {
  width: 20% ;
}

.ItemHistoric3 {
  width: 33.5% ;
}

#Historic {
  width: 65px;
  height: 65px;
  position: absolute;
  margin-top: -85px;
}

#Cases {
  width: 65px;
  height: 65px;
  position: absolute;
  margin-top: -113px;
}

#Client {
  width: 65px;
  height: 65px;
  position: absolute;
  margin-top: -85px;
}

#Arquitetura {
  position: absolute;
  width: 60px;
  height: 60px;
  margin-top: 2vw;
}



@media (max-width: 1450px) {
  #Arquitetura {
    margin-top: 5px;
}
}

@media (max-width: 1400px) {
  .ItemHistoric1 {
    width: 255px;
    margin-top: -25px;
  }
  
  .ItemHistoric2 {
    width: 180px;
  }
  
  .ItemHistoric3 {
    width: 298px;
    margin-top: -35px;
  }
}


@media (max-width: 1350px) {
  .SliderClientes .slick-list {
    padding-top: 19%;
}

#Arquitetura {
  margin-top: -28px;
}
}  

@media (max-width: 1250px) {
  .InputTextArea {
    height: 120px;
  }

  .InputForm {
    height: 40px;
  }
}

@media (max-width: 1170px) {
  .SliderCases1 .slick-next {
    right: 41% !important;
  }
}

@media (max-width: 1080px) {
  .SliderImage {
    margin: 0 auto;
    width: 660px;
  }
}

@media (max-width: 1000px) {
  .IframeEventos {
    width: 85%;
    height: 64vw;
  }
}

@media (max-width: 950px) {
  .SliderClientes .slick-list {
    padding-top: 30px;
}

.SliderCases1 .slick-prev {
  left: 3% !important;
}

.SliderCases1 .slick-next {
  right: 3% !important;
}

  .SliderClientes .slick-prev {
    left: 10vw !important;
    top: 290px;
  }
  
  .SliderClientes .slick-next {
    right: 10vw !important;
    top: 290px;
  }
  

/*
  .SliderClientes .slick-slide {
    align-items: center !important;
  }  
 
  .SliderClientes .slick-next {
    right: -15px !important;
}
  
  .SliderClientes .slick-prev {
    left: -30px !important;
  }
   */
}

@media (max-width: 910px) {
  .ItemHistoric2 {
    width: 195px;
  }

  .ItemHistoric3 {
    width: 255px;
    margin-top: 25px;
}
}

@media (max-width: 850px) {
  .IframeShow {
    width: 100%;
    height: 48vw;
    margin-top: 30px;
  }

  .IframeShow2Desk {
    display: none;
  }

  .IframeShow2Resp {
    display: block;
  }
}

@media (max-width: 830px) {
.SliderImage {
  width: 80%;
}

.SliderImage .slick-next {
  right: -6vw !important;
}

.SliderImage .slick-prev {
  left: -6vw !important;
}
}

@media (max-width: 550px) {
  .SliderClientes .slick-prev {
    top: 172px;
}

.SliderClientes .slick-next {
  top: 172px;
}
}


@media (max-width: 527px) {
  .ItemHistoric1 {
    margin: 0 auto;
  }  
  
  .ItemHistoric2 {
    width: 245px;
    margin: 0 auto;
    margin-top: 30px;
  }  
  
  .ItemHistoric3 {
    margin: 0 auto;
    margin-top: 22px;
  }

  #Client {
    margin-top: -60px;
  }

  #Arquitetura {
    margin-top: -98px;
  }

  #Contact {
    width: 60px;
    height: 60px;
    position: absolute;
    margin-top: -70px;
  }

  
}

@media (max-width: 480px) {
.SliderBanner {
  padding-top: 90px;
}

.SliderImage .slick-next {
  right: -7vw!important;
}

.SliderImage .slick-prev {
  left: -8vw!important;
}
}